/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    pre: "pre",
    code: "code",
    ul: "ul",
    li: "li",
    h3: "h3"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "day-2-i-was-told-there-would-be-no-math"
  }, "Day 2: I Was Told There Would Be No Math"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://adventofcode.com/2015/day/2"
  }, "https://adventofcode.com/2015/day/2")), "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR: ", React.createElement(_components.a, {
    href: "https://github.com/NickyMeuleman/scrapyard/blob/main/advent_of_code/2015/src/day_02.rs"
  }, "my solution in Rust"))), "\n", React.createElement(_components.p, null, "The elves would like to order the exact amount of wrapping paper they need to wrap all presents."), "\n", React.createElement(_components.p, null, "Each present is a perfectly rectangular box."), "\n", React.createElement(_components.p, null, "Today’s input is a list with the dimensions of each gift."), "\n", React.createElement(_components.p, null, "An example input looks like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt",
    title: "input.txt"
  }, "29x13x26\n11x11x14\n27x2x5\n")), "\n", React.createElement(_components.p, null, "Each line has the form of ", React.createElement(_components.code, null, "LxWxH"), "."), "\n", React.createElement(_components.p, null, "For the first present in the list above:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "length: 29"), "\n", React.createElement(_components.li, null, "width: 13"), "\n", React.createElement(_components.li, null, "height: 26"), "\n"), "\n", React.createElement(_components.p, null, "Those numbers are unitless.\nLet’s say Santa uses the imperial system to measure presents.\nThey’re measured in feet."), "\n", React.createElement(_components.h2, {
    id: "part-1"
  }, "Part 1"), "\n", React.createElement(_components.p, null, "The required amount of wrapping paper for one present:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Enough to cover all sides"), "\n", React.createElement(_components.li, null, "Some extra: the area of the smallest side"), "\n"), "\n", React.createElement(_components.p, null, "The question asks how many square foot of wrapping paper is needed to wrap all presents."), "\n", React.createElement(_components.h3, {
    id: "main-code-for-part-1"
  }, "Main code for part 1"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_02.rs"
  }, "pub fn part_1(input: &str) -> i32 {\n    input\n        .lines()\n        .map(|line| {\n            let mut nums = line.split('x').map(|s| s.parse::<i32>().unwrap());\n            // dimensions\n            let l = nums.next().unwrap();\n            let w = nums.next().unwrap();\n            let h = nums.next().unwrap();\n            // sides\n            let lw = l * w;\n            let wh = w * h;\n            let lh = l * h;\n            let smallest = lw.min(wh).min(lh);\n\n            2 * lw + 2 * wh + 2 * lh + smallest\n        })\n        .sum()\n}\n")), "\n", React.createElement(_components.h2, {
    id: "part-2"
  }, "Part 2"), "\n", React.createElement(_components.p, null, "The elves need to order ribbon too."), "\n", React.createElement(_components.p, null, "The required amount of ribbon for one present:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Enough to wrap around the smallest perimeter"), "\n", React.createElement(_components.li, null, "Some extra for a bow: the cubic feet of volume for the present"), "\n"), "\n", React.createElement(_components.p, null, "The question asks how many feet of ribbon is needed to wrap all presents with a bow."), "\n", React.createElement(_components.p, null, "A minor change from part 1: I sorted the dimensions to be able to use the 2 smallest ones."), "\n", React.createElement(_components.h3, {
    id: "main-code-for-part-2"
  }, "Main code for part 2"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_01.rs"
  }, "pub fn part_2(input: &str) -> i32 {\n    input\n    .lines()\n    .map(|line| {\n        let mut nums = line.split('x').map(|s| s.parse::<i32>().unwrap()).sorted_unstable();\n        // dimensions from small to big\n        let dim1 = nums.next().unwrap();\n        let dim2 = nums.next().unwrap();\n        let dim3 = nums.next().unwrap();\n    \n        2 * dim1 + 2 * dim2 + dim1 * dim2 * dim3\n    })\n    .sum()\n}\n")), "\n", React.createElement(_components.h2, {
    id: "final-code"
  }, "Final code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_02.rs",
    numberLines: true
  }, "use itertools::Itertools;\n\npub fn part_1(input: &str) -> i32 {\n    input\n        .lines()\n        .map(|line| {\n            let mut nums = line.split('x').map(|s| s.parse::<i32>().unwrap());\n            // dimensions\n            let l = nums.next().unwrap();\n            let w = nums.next().unwrap();\n            let h = nums.next().unwrap();\n            // sides\n            let lw = l * w;\n            let wh = w * h;\n            let lh = l * h;\n            let smallest = lw.min(wh).min(lh);\n\n            2 * lw + 2 * wh + 2 * lh + smallest\n        })\n        .sum()\n}\n\npub fn part_2(input: &str) -> i32 {\n    input\n    .lines()\n    .map(|line| {\n        let mut nums = line.split('x').map(|s| s.parse::<i32>().unwrap()).sorted_unstable();\n        // dimensions from small to big\n        let dim1 = nums.next().unwrap();\n        let dim2 = nums.next().unwrap();\n        let dim3 = nums.next().unwrap();\n    \n        2 * dim1 + 2 * dim2 + dim1 * dim2 * dim3\n    })\n    .sum()\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
